:root {
	--black: #000000;
	--white: #ffffff;
	--grey: #ededed;
	--light-grey: #dbdbdb;
	--light-grey-69: rgba(252, 252, 252, 0.69);
	--dark-grey: #545454;
	--dark-grey-90: rgba(84, 84, 84, 0.9);
	--dark-heavy-grey: #383838;

	--text-extra-small: 0.7em;
	--text-small: 0.8em;
	--text-medium: 1em;
	--text-large: 1.3em;
	--text-extra-large: 1.6em;
}
