.navbar {
	padding: 4px;
	position: fixed;
	z-index: 9999;
	top: 0;
	width: 100%;
	border-bottom-color: #707070;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	background-image: linear-gradient(
		45deg,
		#405de6,
		#5851db,
		#833ab4,
		#c13584,
		#c13584,
		#e1306c,
		#fd1d1d
	);
}

#desktop-menu {
	display: none;
}

#main-logo {
	width: 175px;
	cursor: pointer;
	margin: 4px 18px 0 0;
}

.nav_logo {
	position: absolute;
	top: 4px;
	left: 6px;
}

.navbar-list {
	display: flex;
	list-style: none;
	gap: 36px;
}

.navbar-list-center {
	display: flex;
	justify-content: space-around;
	list-style: none;
	gap: 36px;
}

.navbar-link {
	color: #fff;
	text-decoration: none;
	font-size: 1rem;
	font-weight: 500;
	white-space: nowrap;
}

.navbar-link:hover {
	color: #fff;
	text-decoration: none;
}

.navbar-link:active {
	color: #fff;
	text-decoration: none;
}

.navbar-link:visited {
	color: #fff;
	text-decoration: none;
}

.navbar-link.active {
	text-decoration: underline;
	font-weight: 700;
}

.navbar-link-button {
	position: relative;
	color: #fff;
	text-decoration: none;
	font-size: 1rem;
	padding: 7px 16px;
	border: 2px solid;
	border-radius: 16px;
	font-weight: 700;
}

.navbar-link-button.profilo-link:hover {
	border-color: rgb(255, 204, 50);
}

.navbar-link-button.logout-link:hover {
	border-color: rgb(251, 31, 35);
}

.navbar-link-button-cart {
	display: block;
	width: 50px;
	height: 29px;
	margin-top: -5px;
}

.push-right {
	margin-left: auto;
}

#root {
	background-color: #000;
	color: #fff;
}

.container {
	background-color: #000;
	min-height: 60vh;
}

.btn.active {
	background-image: linear-gradient(
		45deg,
		#405de6,
		#5851db,
		#833ab4,
		#c13584,
		#c13584,
		#e1306c,
		#fd1d1d
	);
	color: white;
}

.btn.active.btn-verify {
	min-width: 99px;
}

.btn.active.btn-spotify {
	background-color: #1db954;
	background-image: none;
}

.btn.active.btn-tiktok {
	background-color: black;
	border: #f3f3f3 1px solid;
	background-image: none;
}

.btn.active.btn-youtube {
	background-color: #ff0000;
	background-image: none;
}

.btn {
	display: inline-block;
	align-items: center;
	justify-content: center;
	column-gap: 0.5rem;
	background-color: #f3f3f3;
	color: black;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5;
	padding: 10px 20px;
	border-radius: 50px;
	width: fit-content;
	cursor: pointer;
	min-width: fit-content;
	border: 0;
}

.socials {
	display: none;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	list-style: none;
	gap: 20px;
}

.line {
	width: 1px;
	height: 100%;
	background-color: #fff;
	flex: 1;
}

.footer {
	background-color: #111111;
	border-top: 1px solid white;
	padding-top: 5px;
	padding-bottom: 50px;
}

.main-container {
	padding-bottom: 50px;
	margin-top: -54px;
	padding-top: 100px;
}

.input-text {
	width: 100%;
	border: none;
	border-radius: 20px;
	padding: 10px;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 500;
}

.input-text.contacts {
	border-radius: 30px;
	padding: 20px 30px;
	margin-bottom: 30px;
}

textarea.contacts {
	border-radius: 30px;
	padding: 30px 30px;
	margin-bottom: 30px;
	overflow: hidden;
	resize: vertical;
	min-height: 120px;
}

.input-select.contacts {
	border-radius: 30px;
	padding: 20px 30px;
	width: 100%;
	margin-bottom: 30px;
	font-size: 16px;
	font-weight: 500;
}

.input-select.sortBy {
	border-radius: 30px;
	padding: 10px 10px;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 500;
}

.service {
	text-align: center;
	border-width: 2px;
	border-style: solid;
	border-radius: 16px;
	border-color: rgb(84, 83, 221);
	padding: 28px 28px;
	height: 100%;
	display: flex;
	flex-direction: column;
	transition: transform 0.3s ease;
}

.service:hover {
	transform: scale(1.05);
}

.service-content {
	font-size: 1.4rem;
	font-weight: 600;
}

.service-content > p {
	font-size: 18px;
	font-weight: 400;
	display: inline;
}
.service-content > p:before {
	content: "@";
}

.service-content-items {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0px 4px;
}
.service-content-items div {
	display: flex;
	flex-direction: column-reverse;
}

.service-content-items-details {
	font-size: 12px;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.455);
	margin: 0;
}

.service-content-items-numbers {
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 0px;
}

.service-content2 {
	font-size: 1.4rem;
	font-weight: 600;
}

.service-content2 > p {
	font-size: 18px;
	font-weight: 400;
	display: inline;
}

.service-content2-items {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0px 4px;
}
.service-content2-items div {
	display: flex;
	flex-direction: column-reverse;
}

.service-content2-items-details {
	font-size: 12px;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.455);
	margin: 0;
}

.service-content2-items-numbers {
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 0px;
}

.services {
	font-size: 1.4rem;
	font-weight: 700;
	margin: 24px 0px;
	color: #5851db;
}

.service-name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.service-img > img {
	width: 80px;
	height: 80px;
	object-fit: cover;
	border-radius: 50%;
	margin-bottom: 10px;
}

.service:hover:before {
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.service:hover:after {
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.service:hover {
	border-color: rgb(232, 232, 255);
	transition-duration: 0.3s;
}

.navbar .add-to-cart {
	fill: #5453dd;
	cursor: pointer;
	padding: 4px 2px 0px 0px;
}

.services .add-to-cart {
	min-width: 25px;
}

.add-to-cart {
	fill: #5453dd;
	cursor: pointer;
}

.add-to-cart-desk {
	fill: rgb(255 204 50);
	position: absolute;
	top: 2px;
	left: 12px;
	padding: 4px 2px 0px 0px;
}

.add-to-cart:hover {
	fill: rgb(84, 83, 221) !important;
}

.add-to-cart:active {
	transform: translateY(4px);
}
.servizi-disponibili {
	font-size: 3rem;
	font-weight: 700;
}
.categories {
	-ms-overflow-style: none !important;
	scrollbar-width: none !important;
	flex-wrap: wrap;
	justify-content: center;
}

/* width */
.categories::-webkit-scrollbar {
	width: 1px;
}

/* Track */
.categories::-webkit-scrollbar-track {
	background: black;
}

/* Handle */
.categories::-webkit-scrollbar-thumb {
	background: black;
}

/* Handle on hover */
.categories::-webkit-scrollbar-thumb:hover {
	background: black;
}

.class123 {
	text-align: center;
}

.class1234 {
	display: none;
}

@media (max-width: 1000px) {
	#desktop-menu {
		display: none;
	}
}

@media (min-width: 1000px) {
	#desktop-menu {
		display: block;
	}

	#mobile-menu {
		display: none !important;
	}
}

@media (min-width: 1025px) {
	/* big landscape tablets, laptops, and desktops */
	.categories {
		justify-content: center;
	}

	.class123 {
		text-align: right;
	}

	.socials {
		display: flex;
	}

	.class1234 {
		display: block;
	}
}

@media (min-width: 1281px) {
	/* hi-res laptops and desktops */
	.categories {
		justify-content: center;
	}

	.class123 {
		text-align: right;
	}

	.socials {
		display: flex;
	}

	.class1234 {
		display: block;
	}
}

.sidebar {
	margin-top: 70px;
}
.sidebar-item-text {
	margin-top: 20px;
}

.sidebar-item-text.active {
	font-weight: 700;
	text-decoration: underline;
}

.add-to-cart-box {
	position: relative;
}

.add-to-cart-box-after {
	content: "";
	position: absolute;
	top: -6px;
	right: -12px;
	width: 12px;
	height: 12px;
	background-color: rgb(84, 83, 221);
	color: white;
	border-radius: 50%;
	font-size: 0.6rem;
}

.notification-icon--fixed {
	position: absolute;
	top: -7px;
	right: -7px;
	color: #fff;
	background-color: rgb(84, 83, 221);
	border-radius: 50%;
	font-family: "Roboto";

	/* Alignment */
	line-height: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	/* Adjust as required: */
	padding: 5px;
}

/* Height = width */
.notification-icon--fixed::after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.notification-badge {
	font-size: 0.7em;
}

.navbar nav {
	width: 100%;
	height: auto;
	position: absolute;
	top: 64px;
	/* background-color: rgb(84, 83, 221); */
	background-image: linear-gradient(
		108deg,
		#405de6,
		#5851db,
		#833ab4,
		#c13584,
		#c13584,
		#e1306c,
		#fd1d1d
	);
	text-align: center;
}

.navbar nav ul {
	list-style: none;
	padding-left: 0px;
}

.navbar nav ul li {
	padding: 10px 20px;
}

.navbar nav ul li a {
	color: #fff;
	text-decoration: none;
	font-weight: 500;
}

#mobile-menu {
	width: 100%;
	height: 64px;
	position: fixed;
	top: 0;
	left: 0;
	background-image: linear-gradient(
		45deg,
		#405de6,
		#5851db,
		#833ab4,
		#c13584,
		#c13584,
		#e1306c,
		#fd1d1d
	);
}

#mobile-menu summary {
	list-style: none;
}

/* Per browser Chromium */
#mobile-menu summary::-webkit-details-marker {
	display: none;
}

#burger {
	position: absolute;
	right: 15px;
	top: 15px;
	display: inline-block;
	cursor: pointer;
}
/* Burger icon */
#burger span {
	display: block;
	width: 35px;
	height: 5px;
	background-color: rgb(255, 255, 255);
	margin: 6px 0;
}

#mobile-menu {
	display: block;
}

.confirmation-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.confirmation-popup-content {
	background: white;
	padding: 20px;
	border-radius: 5px;
	text-align: center;
}

.confirmation-popup-content p {
	margin-bottom: 20px;
}

.confirmation-popup-content .btn {
	margin: 5px;
}

.sortByDiv {
	text-align: right;
}

.sortByDiv p {
	margin-right: 200px;
}

@media screen and (max-width: 1024px) {
	.sortByDiv {
		text-align: center;
	}
	.sortByDiv p {
		margin-right: 0px;
	}
}

.star {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 35px;
	height: 35px;
	background: url("https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Plain_Yellow_Star.png/1024px-Plain_Yellow_Star.png")
		no-repeat center center;
	background-size: contain;
}

.green-arrow {
	display: inline-block;
	width: 25px;
	height: 25px;
	background: url("./down.png") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

.table-responsive {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

.table-responsive table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	background-color: transparent;
}

.article-content img {
	max-width: 100%;
	height: auto;
	display: block; /* To remove any potential inline space below the image */
	object-fit: contain; /* Maintain aspect ratio and fit within the container */
}

.discount-info {
	display: inline-block;
	position: relative;
}

.old-price {
	text-decoration: line-through;
	color: #ff0000;
	margin-right: 10px;
}

.discount-percentage {
	color: #32a852;
	font-size: 0.9rem;
	position: absolute;
	top: -10px;
	right: -30px;
}

.new-price {
	font-size: 1.1rem;
}

.reactour__mask {
	padding: 50px; /* Adjust padding to make the highlighted area smaller */
	border-radius: 10px; /* Adjust as needed */
}

.reactour__helper {
	left: 20px !important; /* Adjust as needed */
	top: 20px !important; /* Adjust as needed */
}

.reactour__popover {
	color: black !important;
}
