.product {
	padding: 20px;
	/* border: 1px solid red; */
}

.product-image {
	margin-bottom: 10px;
	width: 100%;
	max-height: 250px;
	height: 250px;
	object-fit: cover;
	/* border: 1px solid green; */
	cursor: pointer;
}

.product-infos {
	text-align: left;
	font-size: var(--text-small);
	width: 73%;
	display: inline-block;
}

.product-actions {
	vertical-align: top;
	width: 27%;
	display: inline-block;
	text-align: right;
}

.product-actions > span > img {
	width: 24px;
	vertical-align: middle;
	margin-left: 13px;
	cursor: pointer;
}

.product-name {
	display: block;
	font-weight: 700;
	cursor: pointer;
}

.products-table-infos {
	margin-top: 48px;
	margin-bottom: 96px;
}

.products-table-infos > div {
	vertical-align: middle;
}

.products-container {
	background-color: white;
	margin-bottom: 22px;
	padding: 16px;
}

.products-container-header {
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.products-container-header > .left > img {
	width: 20px;
	margin-right: 30px;
	vertical-align: top;
}

.products-container-header > .left > a > span {
	text-transform: uppercase;
	vertical-align: top;
	font-weight: 600;
	font-size: var(--text-medium);
	cursor: pointer;
}

.products-container-header > .right {
	text-align: right;
}

.products-container-header > .right > img {
	width: 20px;
	cursor: pointer;
}

.products-container-body {
	margin-top: 35px;
	margin-left: 0px !important;
	margin-right: 0px !important;
	padding-left: 10px;
	padding-right: 10px;
}

.container-product {
	padding-right: 5px !important;
	padding-left: 5px !important;
}

.container-product > img {
	width: 100%;
	padding: 8px;
	cursor: pointer;
}

.container-product-other {
	padding-right: 5px !important;
	padding-left: 5px !important;
}

.container-product-other > div {
	background-color: #d0d0d0;
	width: 100%;
	height: calc(100% - 4px);
	text-align: center;
	cursor: pointer;
}

.container-product-other > div > span {
	font-weight: 600;
	font-size: var(--text-large);
	display: block;
	padding-top: 35%;
}

.single-product-name {
	font-weight: 500;
	font-size: var(--text-large);
	display: block;
	padding-bottom: 10px;
}

.single-product-code {
	font-weight: 600;
	font-size: var(--text-small);
	display: block;
	margin-top: 10px;
	text-transform: uppercase;
}

.single-product-images {
	height: 483px !important;
	overflow-y: scroll;
}

.single-product-images-print {
	height: 240px !important;
}

@media screen and (max-width: 1600px) {
	.single-product-images {
		height: 350px !important;
	}
}

@media screen and (max-width: 1200px) {
	.single-product-images {
		height: 290px !important;
	}
}

@media screen and (max-width: 992px) {
	.single-product-images {
		height: 220px !important;
	}
}

.single-product-images > img {
	width: 100%;
	margin-bottom: 10px;
	cursor: pointer;
}

.single-product-images-print > img {
	width: 100%;
	margin-bottom: 10px;
	cursor: pointer;
}

.single-product-main-image > img {
	width: 100%;
	cursor: pointer;
}

.single-product-actions {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
	border-bottom: 1px solid var(--dark-grey);
	justify-content: end;
}

.single-product-actions > span > img {
	width: 30px;
	cursor: pointer;
}

.related-items {
	margin-top: 100px;
	font-weight: 700;
	font-size: var(--text-medium);
	border-top: 1px solid #dbdbdb;
	padding-top: 15px;
}

.related-items > div {
	padding-left: 0px !important;
}

.related-items-content {
	margin-bottom: 100px;
	flex-flow: row !important;
	overflow: scroll;
	scroll-snap-type: x mandatory;
}

.product-container {
	scroll-snap-align: start;
	scroll-snap-stop: normal;
}

.product-modal-title {
	border-bottom: 1px solid var(--dark-heavy-grey);
	padding-left: 0px !important;
	margin-bottom: 10px;
}

.product-modal-image {
	width: 100%;
	object-fit: cover;
}

.status-color {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 5px;
}

.status-color-green {
	background-color: #00a84d;
}

.status-color-red {
	background-color: rgb(255, 0, 0);
}

.status-color-orange {
	background-color: #ff5e00;
}

.status-color-yellow {
	background-color: #ffc800;
}

.status-label {
	font-weight: 600;
	font-size: var(--text-small);
}

#productToPrint {
	display: none;
}

.product-select-checkbox {
	margin-right: 10px;
	cursor: pointer;
	vertical-align: middle;
}

.multiple-actions {
	background-color: white;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	display: inline-block;
	padding: 5px 15px;
}

.multiple-actions > span {
	padding: 10px;
}

.multiple-actions > span > img {
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.product-images {
	position: relative;
}

.product-remove-image {
	position: absolute;
	top: 0px;
	right: 0px;
	background-color: white;
	border-radius: 50%;
	padding: 5px;
	cursor: pointer;
	font-size: var(--text-small);
}
