.input-text > input {
	padding: 12px;
	background-color: var(--grey);
	border: none;
	width: 100%;
	font-size: var(--text-medium);
	border-radius: 12px;
}

.input-text > input::placeholder {
	color: #858585;
	font-style: italic;
}

textarea::placeholder {
	color: #858585;
	font-style: italic;
}

.input-text > input:focus {
	outline: none;
}

.input-checkbox [type="checkbox"] {
	width: 20px;
	height: 20px;
}

.input-checkbox {
	font-size: 20px;
}
