.breacrumbs-row {
	padding: 24px 0px !important;
	border-bottom: 1px solid #dbdbdb;
	margin-bottom: 24px;
}

.breacrumbs-row-np {
	padding: 0px 0px 24px 0px !important;
	border-bottom: 1px solid #dbdbdb;
	margin-bottom: 24px;
}

.breacrumbs-row > div {
	padding: 0px !important;
}

.breadcrumbs {
	display: flex;
	gap: 7px;
	font-size: var(--text-small);
	color: #949494;
	font-weight: 700;
	text-transform: uppercase;
}

.breadcrumbs > div {
	display: flex;
	gap: 7px;
}

.breadcrumbs > div > a,
.breadcrumbs > div > span {
	color: #949494;
}
