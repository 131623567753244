.newTable {
	width: 100%;
	/* background: rgb(255, 255, 255, 0.9); */
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	margin-top: 8px;
	border-collapse: collapse;
}

.newTable th {
	height: 30px;
	color: white;
	font-size: var(--text-size-small);
	cursor: pointer;
	text-transform: uppercase;
	text-align: left;
	padding-left: 15px;
}

.newTable tr {
	border-bottom: 0.6px solid #dadada;
}

.newTable td {
	height: 53px; /* new: 46px */
	padding-left: 15px;
	color: white;
	font-size: var(--text-size-small);
}

.newTable td:first-child {
	font-weight: bold;
}

.newTable th i {
	padding-left: 25px;
}

.iconBtn {
	vertical-align: middle;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	display: inline-block;
	background: #ffffff;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
	cursor: pointer;
}

.iconBtn.edit img {
	width: 20px;
	padding-top: 5px;
	padding-left: 5px;
}

.iconBtn.delete img {
	width: 17px;
	padding-top: 6px;
	padding-left: 8px;
}

.iconBtn.delete {
	margin-left: 15px;
	margin-right: 30px;
}

.iconBtn2 {
	margin-right: 10px;
	vertical-align: middle;
	display: inline-block;
	cursor: pointer;
}

.iconBtn2 > img {
	width: 24px;
	height: 24px;
}

.iconBtn2.edit img {
	padding-right: 5px;
}

.iconDiv {
	float: right;
}

.tableControls {
	width: 100%;
	text-align: right;
	margin-bottom: 60px;
	margin-top: 20px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.tableInfo {
	display: inline-block;
	float: left;
	font-size: var(--text-size-small);
	margin-top: 16px;
	margin-left: 24px;
}

.badge {
	display: inline-block;
	padding: 5px 15px;
	border-radius: 4px;
}

.badge.badge-success {
	background-color: #cdffc0;
}

.badge.badge-danger {
	background-color: #ffd3c0;
}
