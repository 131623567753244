.icon {
  padding-left: 45px !important;
}

.icon-user {
  background: url("icons/user.svg") no-repeat left;
  background-size: 20px;
  background-position-x: 12px;
}

.icon-key {
  background: url("icons/key.svg") no-repeat left;
  background-size: 20px;
  background-position-x: 12px;
}

.icon-mail {
  background: url("icons/mail.svg") no-repeat left;
  background-size: 20px;
  background-position-x: 12px;
}

.icon-home {
  background: url("icons/home.svg") no-repeat left;
  background-size: 20px;
  background-position-x: 12px;
}

.icon-tag {
  background: url("icons/tag.svg") no-repeat left;
  background-size: 20px;
  background-position-x: 12px;
}

