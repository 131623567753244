.pagination {
	display: inline-block;
}

.pagination a {
	color: #333333;
	float: left;
	padding: 6px 18px;
	text-decoration: none;
	font-size: var(--text-small);
	margin: 0;
	cursor: pointer;
	margin-left: 3px;
	border-radius: 3px;
}

.pagination a.active {
	background-color: var(--grey);
	color: black;
}

.pagination a:hover:not(.active) {
	background-color: rgba(255, 204, 50, 0.331);
}

.box-wrapper {
	overflow: hidden;
	width: 100%;
}

.box-black-and-white {
	padding: 20px 0px;
	margin: 4% 0px;
	text-align: center;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.white {
	background-color: #f3f3f3;
	color: black;
	height: 70vh;
	border-radius: 0px;
	margin-top: 0px;
	position: relative;
}

.box-black-and-white h3 {
	font-weight: 600;
	font-size: 32px;
	margin: 0px;
}

.box-black-and-white.black h3 {
	margin-bottom: 20px;
}

.box-black-and-white .btn {
	width: 200px;
	padding: 20px 0px;
	font-size: 22px;
	margin-top: 20px;
}

.white .box-img {
	width: 100%;
	max-width: 600px;
	margin-bottom: 30px;
}

.box-black-and-white .socials {
	display: flex;
	gap: 20px;
	flex-direction: row;
	height: auto;
	margin-bottom: 10px;
}

.cart-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px;
	border: solid 1px;
	margin-bottom: 10px;
	border-radius: 18px;
	position: relative;
}

.cart-item-image img {
	width: 70px;
	border-radius: 50%;
	margin-right: 24px;
}

.cart-item-info {
	display: flex;
	flex-direction: row;
	flex: 1;
	padding-block: auto;
	justify-content: space-between;
	align-items: center;
}

.cart-item-info-name {
	display: flex;
	flex-direction: column;
}

.service-autor {
	color: rgb(84, 83, 221);
}

.cart-item-info-price {
	margin-right: 24px;
	font-weight: 600;
}

.cart-item-info-description {
	margin-top: 6px;
	color: rgba(255, 255, 255, 0.491);
}

.cart-item-remove-title {
	cursor: pointer;
	font-size: 20px;
	position: absolute;
	top: 6px;
	right: 8px;
	color: rgba(255, 255, 255, 0.762);
}

.cart-item-total {
	display: flex;
	padding-top: 0;
	flex-direction: column;
}
.cart-item-total-info {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
}

.cart-item-total-info p:nth-child(2) {
	font-weight: 600;
}

.cart-item-total-info p {
	margin: 0px;
}
.cart-item-total-header {
	font-size: 22px;
	font-weight: 500;
	margin-bottom: 20px;
}

.cart-item-action {
	border-top: 1px solid white;
}
.cart-item-action button {
	width: 100%;
	margin-top: 20px;
}

.greenRate {
	color: rgb(74, 203, 0);
}

.redRate {
	color: rgb(193, 53, 132);
}

@media print {
	.pagebreak {
		page-break-before: always;
	} /* page-break-after works, as well */
}

@media (max-width: 768px) {
	.cart-item-total {
		margin-top: 30px;
	}

	.box-wrapper {
		margin: 0px 0px 0px 0px !important;
	}

	.box-black-and-white.white {
		height: 100vh;
	}

	.box-black-and-white.white h3 {
		font-size: 40px;
	}

	.box-black-and-white.white img {
		margin: 30px 0px;
	}

	.box-black-and-white.black {
		height: 60vh;
	}
}

@media (max-width: 425px) {
	.servizi-disponibili {
		margin-top: 0;
		font-size: 2rem;
	}
	.main-container {
		padding-top: 50px;
	}
}

/* Hide on mobile devices (width less than 768px) */
.hide-mobile {
	display: none;
}

.black {
	margin-top: 150px;
}

@media (min-width: 768px) {
	/* Show on tablets and desktops */
	.hide-mobile {
		display: flex;
	}

	.black {
		margin-top: 0px;
	}
}

/* Hide on tablets and desktops (width 768px and above) */
.hide-desktop {
	display: block;
	height: auto !important;
	padding-bottom: 100px !important;
}

@media (min-width: 768px) {
	/* Hide on tablets and desktops */
	.hide-desktop {
		display: none;
	}
}
