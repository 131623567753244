@import "css/vars.css";
@import "css/navbar.css";
@import "css/login.css";
@import "css/icons.css";
@import "css/inputs.css";
@import "css/texts.css";
@import "css/filters.css";
@import "css/tags.css";
@import "css/images.css";
@import "css/breadcrumbs.css";
@import "css/products.css";
@import "css/pagination.css";
@import "css/tooltip.css";
@import "css/page.css";
@import "css/loans.css";
@import "css/tables.css";
@import "css/slider.css";

* {
	box-sizing: border-box;
}

* {
	font-family: Montserrat;
}

a {
	color: white;
	text-decoration: none;
}

html {
	/* scroll-behavior: smooth; */
	scroll-behavior: auto !important;
}

::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #c4c4c4;
	border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #888;
}

.react-slideshow-container .nav:last-of-type {
	right: 10px !important;
}

.react-slideshow-container .nav:first-of-type {
	left: 10px !important;
}

.uppercase {
	text-transform: uppercase;
}
