.tooltip {
	background-color: #f0f0f0 !important;
	border-radius: 10px !important;
	font-size: var(--text-small) !important;
	color: black !important;
	text-align: center;
}

.tooltip-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	text-align: center;
}

.tooltip-list > li {
	margin: 5px;
	font-size: var(--text-medium);
	cursor: pointer;
}

.tooltip2 {
	background-color: #f0f0f0 !important;
	border-radius: 10px !important;
	font-size: var(--text-small) !important;
	color: black !important;
}

.tooltip2:after {
	border-top: 8px solid #f0f0f0 !important;
}

#default:before {
	opacity: 0 !important;
}

.tooltip {
	z-index: 9999999;
}

.tooltip2 {
	z-index: 9999999;
}

.tooltip:before {
	z-index: 9999999;
}

.tooltip2:before {
	z-index: 9999999;
}

.tooltip:after {
	z-index: 9999999;
}

.tooltip2:after {
	z-index: 9999999;
}
